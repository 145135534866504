<button
  [ngClass]="{ '--has-icon': fontIconName !== '' }"
  [ngClass]="{ '--primary-btn': isPrimary && !disabled, '--secondary-btn': !isPrimary }"
  [ngClass]="{ '--loading': isLoading }"
  [disabled]="disabled"
  type="{{ type }}"
>
  <mat-icon
    *ngIf="fontIconName !== ''"
    aria-hidden="false"
    [attr.aria-label]="iconLabelName"
    fontIcon="{{ fontIconName }}"
  ></mat-icon>

  @if (isLoading) {
    <div class="loading-container">Aguarde <span class="spinner"></span></div>
  } @else {
    {{ text }}
  }
</button>
 