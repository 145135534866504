import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [MatButton, NgIf, NgClass, MatIconModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() disabled = false;
  @Input() fontIconName = '';
  @Input() iconLabelName = '';
  @Input() text!: string;
  @Input() type: 'submit' | 'reset' | 'button' = 'button';
  @Input() isPrimary: boolean = true;
  @Input() isLoading: boolean = false;
}
